"use client"

import { useRouter } from "next/navigation"

const IndexPage = () => {
  const router = useRouter()
  return router.replace("/home/")
}

export default IndexPage
